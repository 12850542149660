@import (reference) '../varello-theme.less';

.skin-orange {
    .full-dark-skin(#f57c00);
}
.skin-teal {
    .full-dark-skin(#3ebfa8);
}
.skin-pink {
    .full-dark-skin(lighten(#c2185b, 4%));
}
.skin-purple {
    .full-dark-skin(#9c27b0);
}
.skin-green {
    .full-dark-skin(darken(#74da05, 3%));
}